@mixin bg_txt($bg, $txt) {
  background-color: $bg;
  color: $txt;
}
@mixin w_h($wide, $high) {
  width: $wide;
  height: $high;
}
@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@mixin f-size($s, $w) {
  font-size: $s;
  font-weight: $w;
}
@mixin border($b, $rad) {
  border: $b;
  border-radius: $rad;
}

@mixin animate($animation,$duration,$method, $times){
  animation: $animation $duration $method $times;
}

@mixin keyframes_fade{
  @keyframes fade{
    0%{
      opacity: 1;
      }
     50%{
      opacity: 0.5;
      }
     100%{
      opacity: 0;
     }
  }
}

@mixin keyframes_moveRight{
  @keyframes moveRight{
    0%{
      width: 25%;
    }
    100%{
      width: 100%;
    }
  }
}

@mixin keyframes_spin{
  @keyframes spin{
    0%{
      transform: rotate(-45deg);
    }
    50%{
      transform: rotate(135deg);
    }
    100%{
      transform: rotate(-45deg);
    }
  }
}

@mixin keyframes_zoomInOut{
  @keyframes zoomInOut{
    0%{
      font-size: small;
    }
    50%{
      font-size: 1.5rem;
    }
    100%{
      font-size: small;
    }
  }
}

@mixin keyframes_bounce{
  @keyframes bounce{
      0%{
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
      }
      60%{
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
      }
      80%{
        transform: translateY(-10px) rotate(-45deg);
      }
      100%{
        transform: translateY(0) rotate(-45deg);
      }
    }
}

@mixin keyframes_pulsate{
@keyframes pulsate{
  0%{
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50%{
    opacity: 1.0;
  }
  100%{
     transform: scale(1.3, 1.3);
    opacity: 0;
  }
}
}