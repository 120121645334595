$bgcolor: #f0f0f0;
$primary-color: #008f95;
$secondary-color: rgb(255, 145, 25);
$textcolor: #000000;


$font-cormorant: 'Cormorant', serif;
$font-trocchi: 'Trocchi',serif;

$sm-size: 1.25rem;
$m-size: 1.75rem;
$lg-size: 3rem;
