@import "../SASS/mixins";
@import "../SASS/variables";

// Title:
// .title h1 {
//   @include f-size(4rem, 900);
//   text-align: center;
// }
// .title p {
//   @include f-size($sm-size, 700);
//   position: relative;
//   text-align: center;
//   text-transform: uppercase;
//   padding-bottom: 5px;
// }
// .title p:before {
//   width: 28px;
//   height: 5px;
//   display: block;
//   content: "";
//   position: absolute;
//   bottom: 3.5px;
//   left: 50%;
//   margin-left: -14px;
//   z-index: 1;
//   background-color: $secondary-color;
// }
// .title p:after {
//   width: 100px;
//   height: 1px;
//   display: block;
//   content: "";
//   position: relative;
//   margin-top: 1rem;
//   left: 50%;
//   margin-left: -50px;
//   background-color: black;
// }

// Into Button
.intro-btn {
  text-align: center;
  .hire-btn,
  .resume-btn {
    padding: 0.5rem 2rem;
    @include bg_txt(transparent, white);
    border-radius: 50px;
    &:hover {
      transform: skew(15deg);
    }
  }
  .resume-btn {
    background: linear-gradient(115deg, rgb(255, 130, 0) 25%, transparent);
  }
}

// Gallery:
.grid__container {
  min-width: 50%;
  min-height: 50rem;
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(8, 1fr);
  // padding: 1rem;
  // box-shadow: 0 14px 28px rgba(0,0,0,0.1), 0 10px 10px rgba(0,0,0,0.05);
  .grid__item {
    border-radius: 0.75rem;
    background-position: center;
    background-size: cover;
    transition: all 400ms ease-in;
    &:hover {
      transform: scaleX(-1);
    }
  }
}
.flip {
  transform: scaleX(-1);
}
.one {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  background-image: url(//unsplash.it/400/400);
}

.two {
  grid-column: 2 / 5;
  grid-row: 1 / 4;
  background-image: url(//unsplash.it/900/900);
}

.three {
  grid-column: 5 / 7;
  grid-row: 1 / 4;
  background-image: url(//unsplash.it/500/400);
}

.four {
  grid-column: 8 / 9;
  grid-row: 1 / 2;
  background-color: $secondary-color;
  opacity: 0.75;
  border-radius: 0 0 0 10rem !important;
}

.five {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  background-color: #a4eee9;
  border-radius: 0 100px 0 0 !important;
}

.six {
  grid-column: 2/ 4;
  grid-row: 4 / 7;
  background-image: url(//unsplash.it/400/500);
}

.seven {
  grid-column: 4/ 7;
  grid-row: 4 / 7;
  background-image: url(//unsplash.it/500/500);
}

.eight {
  grid-column: 7 / 9;
  grid-row: 3 / 6;
  border-radius: 100% !important;
  background-image: url(//unsplash.it/300/300);
}

.nine {
  grid-column: 1 / 4;
  grid-row: 7 / 9;
  background-image: url(//unsplash.it/700/750);
}

.ten {
  grid-column: 4 / 6;
  grid-row: 7 / 10;
  background-image: url(//unsplash.it/700/700);
}

.eleven {
  grid-column: 6 / 9;
  grid-row: 7 / 9;
  background-image: url(//unsplash.it/300/500);
}

.twelve {
  grid-column: 8 / 9;
  grid-row: 9 / 10;
  background-color: #f59ca2;
  opacity: 0.75;
  border-radius: 100px 0 0 0 !important;
}

// Progress Bar
.skill {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
}

.skill-progress {
  padding: 0px 2rem;
}
.barOverflow {
  position: relative;
  overflow: hidden;
  width: 8rem;
  height: 4rem;
  margin: auto;
  margin-bottom: -1rem;
}
.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  box-sizing: border-box;
  border: 0.6rem solid rgb(0, 0, 0);
  border-bottom-color: $secondary-color;
  border-left-color: $secondary-color;
  transform: rotate(-45deg);
  @include animate(spin, 3s, ease-out, 1);
  @include keyframes_spin();
  // animation: name duration timing-function delay iteration-count direction fill-mode;
}
.skill-per {
  @include f-size(1.5rem, 600);
}
.skill-name {
  color: $secondary-color;
  @include f-size(1.75rem, 700);
}

// Map Component

.pin{
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50% 50% 50% 0;
    transform: rotate(-45deg);
    @include animate(bounce, 2s, ease-in, 1);
    @include keyframes_bounce();
  &:after{
    content: "";
    width: 14px;
    height: 14px;
    background: $secondary-color;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 8px;
  }
}  
.pulse{
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position :absolute;
  left: 8px;
  transform: rotateX(55deg);
  
  &:after{
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    // background-color: black;
    border: 4px solid black;
    position: absolute;
    left: -13px;
    top: -14px;
    @include animate(pulsate, 2s, ease-in,infinite);
    @include keyframes_pulsate();
  }
}

// Media Query
@media (max-width: 850px) {
  .grid__container {
    min-width: 50%;
    height: 400px;
    margin-top: 50px;
  }
}

.responsive-map{
height: 50vh;
width: 95%;
margin: 0 auto;
border: 2px solid black;
border-radius: 15px;
padding: 10px;
}
.responsive-map iframe{
  border: 0;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  // position: absolute;
}
