@import "../../SASS/variables";
@import "../../SASS/mixins";

.hero-section {
  //   @include w_h(100%, 40vh);
  @include bg_txt(black, white);
  padding: 5rem 0px;
}

.intro {
  // @include w_h(50%, auto);
  margin: 2rem;
  @include center();
  @include f-size(2.5rem, 600);
  @include bg_txt(transparent, white);

  .social-icons {
    margin-bottom: 1.5rem;

    a {
      padding: 0.8rem;
      color: white;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .skills {
    @include bg_txt(unset, $secondary-color);

    .skill-name {
      @include f-size(4rem, 700);
    }
  }
}



.profile-pic {
  // @include w_h(50vw, 100%);
  margin: 2rem;
  @include center();

  .pic {
    @include w_h(50%, auto);
    @include center();
    border-bottom: 2px solid $secondary-color;
    border-radius: 100%;
    padding: 2rem;

    img {
      transition-timing-function: ease-in;
      transition: 0.2s;
      @include border(2px solid $secondary-color, 100%);

      &:hover {
        transform: scale(1.1);
        transition: 0.25s;
        transition-timing-function: ease-out;
        cursor: pointer;
      }
    }
  }
}


// Desktop View: Media Query...
@media screen and (min-width: 850px) {
  .hero-section {
    display: flex !important;

    .intro,
    .profile-pic {
      margin: auto;
      @include w_h(50vw, 100%);
    }
  }
}