@import "./SASS/mixins";
@import "./SASS/variables";

* {
  // padding: 0%;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 64.5%;
//  overflow-x: hidden;

  body {
    margin: 0%;
    font-family:  cursive;
    font-size: $m-size;
  }
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(249, 197, 28);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 3px;
}

a {
  text-decoration: none !important;
}

.navbar {
  display: block !important;
  padding: 5rem 1rem 2rem 1rem !important;
  text-align: center;
  width: 100%;
  padding: 4rem 1rem 1rem 1rem;
  @include bg_txt(black, white);

  .logo {
    font-family: $font-trocchi;
    @include f-size($lg-size, 500);
    color: $secondary-color !important;
  }
  .logo img{
    // width: 100%;
    background-color: white;
    border-radius: 50%;
    // padding: 20px;
  }

  button {
    background-color: transparent;
    border-radius: 50px;
    width: 5rem;

    &:hover {
      transform: scale(1.1);
    }
  }

  .menubar {
    margin: 1rem;
    height: 80vh;

    ul {
      height: 100%;
      margin-bottom: 0px;
      // display: flex;

      li {
        list-style: none;
        margin: auto;
        margin-bottom: 10px;

        a {
          @include f-size($m-size, 600);
          margin: 0rem 3rem;
          @include bg_txt(transparent, white);
          border: none;

          &:hover {
            color: $secondary-color;
          }
        }

        .active {
          color: $secondary-color;
          border-bottom: 0.2rem solid;
        }
      }
    }
  }
}

footer {
  text-align: center;
  // position: fixed;
  bottom: 0;
  width: 100%;
  @include bg_txt(black, $secondary-color);
  @include f-size(2rem, 600);
}

// Desktop View
@media screen and (min-width: 850px) {
  .navbar {
    display: flex !important;
    justify-content: center !important;
  }

  .menubar {
    height: auto !important;
    display: block !important;
    ul {
      display: flex !important;
    }
  }

  .menu-btn {
    display: none;
  }
}